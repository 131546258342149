<template>
	<div class="padding16">
		<el-collapse v-model="activeNames" @change="handleChange">
			<el-collapse-item name="1">
				<template slot="title">
					<div class="flex_title">
						<div class="text-xxl text-bold its_title_color">{{ title }}</div>
						<div class="title-left">
							<div title="点击咨询" class="cursor streamer margin-right">
								<img src="@/assets/images/order/iconQQ.png" width="26" height="26">
							</div>
							<div title="点击咨询" class="cursor streamer margin-right">
								<img src="@/assets/images/order/weChat.png" width="28" height="28">
							</div>
							联系电话：(客服 08:00-22:00 ) <span class="text-red number-font text-16">400-8076-***</span>
							<el-divider direction="vertical"></el-divider>
							(产品经理)<span class="text-red number-font text-16">谢凯莉183****0237</span>
							<el-divider direction="vertical"></el-divider>
							(经理)<span class="text-red number-font text-16">王秀娟136****4045</span>
						</div>
					</div>
				</template>
				<div class="formsty">
					<el-alert title="消息提示的文案" type="info" description="文字说明文字说明文字说明文字说明文字说明文字说明" show-icon>
					</el-alert>
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
						<div class="flex" v-for="item, index in formData" :key="index" v-if="!index.includes('数量')">
							<el-form-item :label="index" :prop="i.required ? i.propertyName : ''" v-for="i, j in item"
								:key="j">
								<el-radio-group v-model="ruleForm[i.propertyName]" v-if="i.type == 'radio'">
									<div class="flex">
										<el-radio :label="o.title" v-for="o, k in i.field" :key="k">{{ o.title }}
											<!-- <el-select placeholder="请选择" style="width: 150px;"
												v-model="ruleForm[i.propertyName]"
												v-if="o.field">
												<el-option v-for="o, k in i.field" :key="k" :label="o.title"
													:value="o.title">
												</el-option>
											</el-select> -->
											<el-button type="text" @click="openImg(o.img)" v-if="o.img">示例图</el-button>
										</el-radio>
									</div>
								</el-radio-group>
								<div style="display: flex;" v-if="i.type == 'select'">
									<el-select placeholder="请选择" style="width: 150px;"
										v-model="ruleForm[i.propertyName]"
										v-if="!i.custom || !ruleForm[i.custom.propertyName]">
										<el-option v-for="o, k in i.field" :key="k" :label="o.title" :value="o.title">
										</el-option>
									</el-select>
									<div v-if="i.custom && ruleForm[i.custom.propertyName]" style="display: flex;">
										<el-input v-model="ruleForm[i.custom.field[0].propertyName]"
											:placeholder="i.custom.field[0].start"
											style="width: 120px;"></el-input><span class="chacha">×</span><el-input
											v-model="ruleForm[i.custom.field[1].propertyName]" style="width: 120px;"
											:placeholder="i.custom.field[1].end"></el-input>
									</div>
									<el-checkbox v-if="i.custom" v-model="ruleForm[i.custom.propertyName]"
										style="margin-left: 10px;">自定义</el-checkbox>
									<!-- <div class="margin-left" style="width: 64px; height: 36px;margin-bottom: 22px;" >
										<el-image class="solid" fit="contain" style="width: 64px; height: 36px;"
											:src="require('@/assets/images/order/preview-img.png')"
											:preview-src-list="[require('@/assets/images/order/preview-img.png')]"></el-image>
										<i class="el-icon-search icontubiao"></i>
									</div> -->
								</div>
								<el-checkbox-group v-model="ruleForm[i.propertyName]" v-if="i.type == 'checkbox'">
									<el-checkbox :label="p.title" :name="p.title" v-for="p, q in i.field"
										:key="q"></el-checkbox>
								</el-checkbox-group>
								<el-input type="text" v-if="i.type == 'input'" v-model="ruleForm[i.propertyName]"
									placeholder="请输入"></el-input>
							</el-form-item>
						</div>
						<el-divider></el-divider>
						<div class="flex" v-for="item, index in formData" :key="index" v-if="index.includes('数量')">
							<div class="numSty">
								<div class="flex ">
									<el-form-item :label="index" v-for="i, j in item" :key="j"
										:prop="i.required ? i.propertyName : ''">
										<div class="flex">
											<el-radio-group class="noYuan2" v-model="ruleForm[i.propertyName]">
												<el-tooltip class="item" effect="dark" :content="l" placement="top"
													v-for="k, l in i.field" style="width: 96px;">
													<el-radio :label="k.title" :border="true">{{ k.title }}
													</el-radio>
												</el-tooltip>
											</el-radio-group>
											<div class="priceInput">
												自定义：<el-input type="text" style="width: 120px;"></el-input>
											</div>
											<el-button type="primary" @click="submitForm('ruleForm')">计算价格</el-button>
										</div>

									</el-form-item>
								</div>
								<div class="flex ">
									<el-form-item label="官网下单">

									</el-form-item>
								</div>
								<div class="flex ">
									<el-form-item label="人工接单">

									</el-form-item>
								</div>
							</div>
						</div>
						<!-- <el-form-item label="印品细分场景" prop="radio">
							<el-radio-group v-model="ruleForm.radio" class="noYuan">
								<el-radio :label="3" :border="true">会员卡</el-radio>
								<el-radio :label="6" :border="true">PVC名片</el-radio>
								<el-radio :label="9" :border="true">PVC刮刮卡</el-radio>
							</el-radio-group>
						</el-form-item>
						<div class="flex" >
							<el-form-item label="材料" prop="radio">
								<el-radio-group v-model="ruleForm.radio" class="noYuan">
									<el-tooltip class="item" effect="dark" content="Top Center 提示文字" placement="top">
										<el-radio :label="3" :border="true"><i
												class="el-icon-warning-outline"></i>0.38磨砂
											<el-image class="preview-img"
												:src="require('@/assets/images/order/preview-img.png')"
												:preview-src-list="[require('@/assets/images/order/preview-img.png')]"></el-image>
										</el-radio>
									</el-tooltip>
									<el-radio :label="6" :border="true"><i class="el-icon-warning-outline"></i>0.38亮光
										<el-image class="preview-img"
											:src="require('@/assets/images/order/preview-img.png')"
											:preview-src-list="[require('@/assets/images/order/preview-img.png')]"></el-image>
									</el-radio>
									<el-radio :label="9" :border="true"><i class="el-icon-warning-outline"></i>0.38哑光
										<el-image class="preview-img"
											:src="require('@/assets/images/order/preview-img.png')"
											:preview-src-list="[require('@/assets/images/order/preview-img.png')]"></el-image>
									</el-radio>
									<el-radio :label="10" :border="true"><i class="el-icon-warning-outline"></i>0.38透明
										<el-image class="preview-img"
											:src="require('@/assets/images/order/preview-img.png')"
											:preview-src-list="[require('@/assets/images/order/preview-img.png')]"></el-image>
									</el-radio>
								</el-radio-group>
							</el-form-item>
							<div class="margin-left" style="width: 64px; height: 36px;margin-bottom: 22px;">
								<el-image class="solid" fit="contain" style="width: 64px; height: 36px;"
									:src="require('@/assets/images/order/preview-img.png')"
									:preview-src-list="[require('@/assets/images/order/preview-img.png')]"></el-image>
								<i class="el-icon-search icontubiao"></i>
							</div>
						</div>

						<el-form-item label="成品尺寸(毫米)" prop="delivery">
							<div style="display: flex;">
								<el-select v-if="!checked" v-model="ruleForm.delivery" placeholder="请选择"
									style="width: 150px;">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
								<div v-if="checked" style="display: flex;">
									<el-input v-model="inputLong" placeholder="长边"
										style="width: 120px;"></el-input><span class="chacha">×</span><el-input
										v-model="inputSort" style="width: 120px;" placeholder="短边"></el-input>
								</div>
								<el-checkbox v-model="checked" style="margin-left: 10px;">自定义</el-checkbox>
								<div class="margin-left" style="width: 64px; height: 36px;margin-bottom: 22px;">
									<el-image class="solid" fit="contain" style="width: 64px; height: 36px;"
										:src="require('@/assets/images/order/preview-img.png')"
										:preview-src-list="[require('@/assets/images/order/preview-img.png')]"></el-image>
									<i class="el-icon-search icontubiao"></i>
								</div>
							</div>

						</el-form-item>
						<el-form-item label="印面" prop="resource">
							<el-radio-group v-model="ruleForm.resource">
								<el-radio label="正常四色"></el-radio>
								<el-radio label="四色加1专" disabled></el-radio>
								<el-button type="text"
									@click="openImg('https://files.sd2000.com:9600/piscp/craft/%E4%B8%93%E8%89%B2-%E4%B8%93%E8%89%B2%E5%8D%B0-%E5%9B%9B%E8%89%B2%E5%8A%A01%E4%B8%93-%E5%9B%9B%E8%89%B2%E5%8A%A02%E4%B8%93-%E4%B8%89%E8%89%B2%E5%8A%A02%E4%B8%93.jpg')">示例图</el-button>
							</el-radio-group>
						</el-form-item>
						<el-form-item label="印面" prop="type">
							<el-checkbox-group v-model="ruleForm.type">
								<el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
								<el-checkbox label="地推活动" name="type"></el-checkbox>
								<el-checkbox label="线下主题活动" name="type"></el-checkbox>
								<el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
							</el-checkbox-group>
						</el-form-item> -->

						<!-- <el-form-item>
							<el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
							<el-button @click="resetForm('ruleForm')">重置</el-button>
						</el-form-item> -->

					</el-form>
				</div>

			</el-collapse-item>
		</el-collapse>
	</div>
</template>

<script>
import { handleThemeStyle } from "./data.js";
import { getOrderJson } from '@/api/goods/goodscategory';
import Config from "@/utils/config"
export default {
	data: () => {
		return {
			activeNames: ['1'],
			title: '',
			options: [{
				value: '85.5*54',
				label: '85.5*54'
			}],
			checked: false,
			isShowImg: false,
			inputLong: '',
			inputSort: '',
			ruleForm: {
				FanHandle: '',//扇柄
				FanHandleColor: '',//扇柄颜色
				Paper: 'PP料【52丝左右】',//材料
				Size: '15*16CM',//尺寸
				PrintSide: '',//印面
				PrintColor: '',//印色
				Crafts: [],//工艺
				StyleNum: '',//款数
				Num: '',//数量
				long: '',//长边
				short: '',//短边
				customChecked: false,//自定义开关
			},
			formData: {
				"扇柄": [
					{
						"type": "radio",
						"propertyName": 'FanHandle',
						"field": [
							{
								"title": "短铆钉",
								"img": "\/upload\/goods\/dld.png"
							},
							{
								"title": "长铆钉",
								"img": "\/upload\/goods\/cld.png"
							},
							{
								"title": "筷子柄",
								"img": "\/upload\/goods\/kzb.png"
							},
							{
								"title": "无手柄",
								"img": "\/upload\/goods\/wsb.jpg"
							}
						]
					}
				],
				"扇柄颜色": [
					{
						"type": "radio",
						"propertyName": 'FanHandleColor',
						"field": [
							{
								"title": "蓝色"
							},
							{
								"title": "白色透明"
							},
							{
								"title": "黑色"
							},
							{
								"title": "无",
								"img": "\/upload\/goods\/wsb.jpg"
							}
						]
					}
				],
				"材料": [
					{
						"type": "select",
						"propertyName": 'Paper',
						"required": 1,
						"default": "PP料【52丝左右】",
						"field": [
							{
								"title": "PP料【52丝左右】"
							}
						]
					}
				],
				"尺寸(厘米)": [
					{
						"type": "select",
						"propertyName": 'Size',
						"required": 1,
						"default": "15*16CM",
						"field": [
							{
								"title": "15*16CM"
							},
							{
								"title": "16*17CM"
							},
							{
								"title": "17*18CM"
							},
							{
								"title": "18*19CM"
							},
							{
								"title": "19*21CM"
							}
						],
						"custom": {
							"title": "自定义",
							"checked": false,
							"propertyName": 'customChecked',
							"field": [
								{
									"start": "长边",
									"propertyName": 'long'
								}, {
									"end": "短边",
									"propertyName": 'short'
								}
							],


						}
					}
				],
				"印面": [
					{
						"type": "radio",
						"propertyName": 'PrintSide',
						"required": 1,
						"default": 0,
						"field": [
							{
								"title": "双面",
								"type": "select",
								'placeholder': '双面类型',
								"field": [
									{
										"title": "不同",
										"price_increase": 0.01
									},
									{
										"title": "相同"
									},
									{
										"title": "共背"
									}
								]
							},
							{
								"title": "单面",
								"condition": {
									"formula": "=",
									"content": {
										"parameter": "扇柄",
										"field": 3
									}
								}
							}
						]
					}
				],
				"印色": [
					{
						"type": "radio",
						"propertyName": 'PrintColor',
						"required": 1,
						"default": 0,
						"field": [
							{
								"title": "正常四色"
							}
						]
					}
				],
				"工艺": [
					{
						"type": "checkbox",
						"propertyName": 'Crafts',
						"field": [
							{
								"title": "横切"
							},
							{
								"title": "形状",
								"type": "select",
								"img": "\/upload\/goods\/xz.jpg",
								"price_increase": 0.01,
								"condition": {
									"formula": "<>",
									"content": {
										"parameter": "扇柄",
										"field": 3
									}
								},
								"field": [
									{
										"title": "标准模版"
									},
									{
										"title": "异形"
									}
								]
							}
						]
					}
				],
				"款数": [
					{
						"type": "input",
						"propertyName": 'StyleNum',
					}
				],
				"数量(把)": [
					{
						"type": "radio",
						"propertyName": 'Num',
						"required": 1,
						"default": 500,
						"field": {
							"500": {
								"title": "500"
							},
							"1000": {
								"title": "1000"
							},
							"2000": {
								"title": "2000"
							},
							"3000": {
								"title": "3000"
							},
							"5000": {
								"title": "5000"
							}
						},
						"custom": {
							"title": "自定义",
							"start": "500"
						}
					}
				]
			},
			rules: {
				Size: [
					{ required: true, message: '请选择尺寸', trigger: 'change' }
				],
				Paper: [
					{ required: true, message: '请选择材料', trigger: 'change' }
				],
				PrintSide: [
					{ required: true, message: '请选择印面', trigger: 'change' }
				],
				Num: [
					{ required: true, message: '请选择数量', trigger: 'change' }
				],
				type: [
					{ type: 'array', required: true, message: '请至少选择一个活动性质', trigger: 'change' }
				],
				resource: [
					{ required: true, message: '请选择活动资源', trigger: 'change' }
				],
				desc: [
					{ required: true, message: '请填写活动形式', trigger: 'blur' }
				]
			}
		}
	},
	created() {
		this.yourMethod();
	},
	mounted() {

		handleThemeStyle('#ffc400')
	},
	watch: {
		// 监听路由对象
		$route: {
			handler: function (to, from) {
				// 如果当前路由是你的页面路由
				console.log(to, 'to');
				if (to.path.includes('orderCreateByOfficial')) {
					// 调用你想要的方法
					this.yourMethod();
				}
			},
			// 设置为深度监听
			deep: true
		}
	},
	computed: {
		hasNum(string) {
			console.log(string, '999');
			if (string.includes('数量')) {
				return true
			} else {
				return false
			}
		}
	},
	methods: {
		getJson(id) {
			getOrderJson(id)
				.then(res => {
					if (res.code == 0 && res.data) {
						this.formData = res.data
					}
				})
				.catch(err => {
					this.$message.error(err.message);
				});
		},
		openImg(url, title) {
			if (url) {
				this.$alert(`<p><img src="${Config.imgUrl + url}" alt="" style="width: 100%;"></p>`, title, {
					dangerouslyUseHTMLString: true,
					showConfirmButton: false
				});
			}

		},
		showImg(url) {
			console.log(url, 'url');
		},
		submitForm(formName) {
			console.log(this.ruleForm, 'ruleForm');
			this.$refs[formName].validate((valid) => {
				if (valid) {
					alert('submit!');
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		yourMethod() {
			console.log(this.$store.state.app.tree, 'tree')
			console.log(this.$route, 'rrr');
			let tree = this.$store.state.app.tree
			if (this.$route.query && this.$route.query.categoryId) {
				let id = this.$route.query.categoryId
				this.getTitle(tree, id)
				// if (this.$route.query.categoryId == 38668) {
				// 	this.getJson(this.$route.query.categoryId)
				// }

				console.log(this.title);
			}
		},
		getTitle(ary, id) {
			for (let i of ary) {
				if (i.category_id == id) {
					this.title = i.category_name + '在线报价'
					return
				}
				if (i.goods_id == id) {
					this.title = i.goods_name + '在线报价'
					return
				}
				if (i.goods_list) {
					this.getTitle(i.goods_list, id)
				}
			}
		},
		handleChange(val) {
			console.log(val);
		}
	}
}
</script>

<style lang="scss" scoped>
::v-deep .el-tabs__item {
	position: relative;
	font-size: 16px;
	padding: 0 24px !important;
}

::v-deep .el-tabs__content {
	padding: 8px;
	width: 100%;
	height: 100%;
}


::v-deep .el-upload {
	width: 100%;

}

::v-deep .el-upload-dragger {
	width: 100%;
	height: 360px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

::v-deep .el-upload-dragger .el-icon-upload {
	margin: 0;
}

.padding16 {

	padding: 16px;
	width: 100%;
	box-sizing: border-box;
	background-color: #F8F8F8;
}

::v-deep .el-collapse-item__header {
	color: #131415;
	font-size: 20px;
	font-weight: bold;
	border-left: 4px solid #ffc400;
	border-bottom: 1px solid #eee;
	padding-left: 16px;
	box-sizing: border-box;
}

.demo-ruleForm {
	padding-top: 16px;
	box-sizing: border-box;
}

.formsty {
	padding: 16px;
	box-sizing: border-box;
}

.flex_title {
	display: flex;
	align-items: center;
	width: 100%;

	.title-left {
		margin-left: 16px;
		display: flex;
		align-items: center;
		color: #666;
		font-size: 14px;
	}

	.streamer {
		position: relative;
		overflow: hidden;
	}

	.streamer::before {
		position: absolute;
		content: '';
		top: -50%;
		left: -50%;
		width: 200%;
		height: 200%;
		background: -webkit-linear-gradient(45deg, transparent 30%, #fff 35%, transparent 40%);
		background: linear-gradient(45deg, transparent 30%, #fff 35%, transparent 40%);
		background-size: 200% 100%;
		animation: Streamer 4s linear infinite;
		-webkit-animation: Streamer 4s linear infinite;
		z-index: 10;
	}

	.text-16 {
		font-size: 16px;
	}

	.text-red {
		color: #FF6445;
	}
}

@keyframes Streamer {
	0% {
		background-position: 100% 0;
	}

	40% {
		background-position: -100% 0;
	}

	100% {
		background-position: -100% 0;
	}
}

::v-deep .custom-alert .el-alert--info.is-light {
	color: #428dfa !important;
	background-color: #f5f9ff;
	border-color: #dfecfe;
}

::v-deep .el-alert--info.is-light {
	color: #428dfa !important;
	background-color: #f5f9ff;
	border-color: #dfecfe;
}

::v-deep .el-alert--info .el-alert__description {
	color: #428dfa !important;
}

::v-deep .el-alert.is-light .el-alert__closebtn {
	color: #428dfa !important;
}

::v-deep .el-form-item__label {
	background: #fdf4d7;
	margin-right: 16px;
}

::v-deep .numSty .el-form-item {
	margin-bottom: 1px;
}

::v-deep .noYuan2 {
	.el-radio__input {
		display: none;
	}

	.el-radio {
		margin-right: 0;
		text-align: center
	}
}

.priceInput {
	margin-left: 10px;
	margin-right: 16px;
}

::v-deep .noYuan {
	.el-radio {
		min-width: 210px;
		margin-right: 0;
		position: relative;
	}

	.el-radio__input {
		display: none;
	}

	.el-radio.is-bordered.is-checked::before {
		content: '';
		position: absolute;
		bottom: 4px;
		right: 1px;
		display: block;
		width: 6px;
		height: 3px;
		border: 2px solid;
		border-color: transparent transparent #fff #fff;
		z-index: 10;
		transform: rotate(-45deg);
	}

	.el-radio.is-bordered.is-checked::after {
		content: '';
		position: absolute;
		bottom: 0;
		right: 0;
		display: block;
		width: 0;
		height: 0;
		border: 10px solid;
		border-color: transparent #ffc400 #ffc400 transparent;
		z-index: 9;
	}

	.preview-img {
		position: absolute;
		top: 0;
		right: 0;
		width: 42px;
		height: 14px;
		display: none
	}

	.is-checked {
		.preview-img {
			display: block;
		}
	}


}

.margin-left {
	margin-left: 16px;
	position: relative;

	.solid {
		position: relative;
	}

	.solid::after {
		border: 2px solid #eee;
		content: " ";
		width: 200%;
		height: 200%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: inherit;
		transform: scale(.5);
		transform-origin: 0 0;
		pointer-events: none;
		box-sizing: border-box;
	}

	.icontubiao {
		position: absolute;
		bottom: 0px;
		right: 0px;
		width: 15px;
		height: 15px;
		line-height: 15px;
		background-color: rgb(255, 196, 0);
		pointer-events: none;
		color: #fff;
	}
}

.flex {
	display: flex;
	align-items: center
}

::v-deep .el-form-item__content {
	margin-left: 136px !important;
}

.chacha {
	font-size: 24px;
	margin-left: 16px;
	margin-right: 16px;
	color: #979594;
}
</style>
